<template>
  <div>
    <p class="text-center text-14 text-bold p-b-5">履约分析</p>

    <Row :gutter="16">
      <i-col span="12" class="analysis-border-right">
        <p class="text-14 text-bold">供应商能力</p>
        <Row  class="table-title text-right">
          <i-col span="4" class="text-left">供应商</i-col>
          <i-col span="4">工单</i-col>
          <i-col span="4">已完成</i-col>
          <i-col span="4">工单占比</i-col>
          <i-col span="4">及时完成率</i-col>
          <i-col span="4">及时率涨幅</i-col>
        </Row>
        <Row v-for="(item,index) in supplierAbilityData" :key="'1_' + index" class="p-t-5 text-right">
          <i-col span="4" class="text-left">
            <Tooltip v-if="item.name.length>6" placement="right-start" :content="item.name">
              {{item.name.substr(0,6)}}..
            </Tooltip>
            <span v-else>{{item.name}}</span>
          </i-col>
          <i-col span="4">{{item.totalNumber}}</i-col>
          <i-col span="4">{{item.actualNumber}}</i-col>
          <i-col span="4">{{item.orderRate}} %</i-col>
          <i-col span="4">{{item.rate}} %</i-col>
          <i-col span="4">
            <span :class="getChainRateColor(item.chainRate)">{{item.chainRate}}%</span>
          </i-col>
        </Row>
        <p v-if="supplierAbilityData.length===0" class="remark p-t-20 text-center">暂无数据</p>
      </i-col>
      <i-col span="12">
        <p class="text-14 text-bold">履约节点统计</p>
        <Row  class="table-title" :gutter="4">
          <i-col span="4" offset="1">类型</i-col>
          <i-col span="3">素材上传</i-col>
          <i-col span="3">内容审批</i-col>
          <i-col span="3">上刊设置</i-col>
          <i-col span="4">供应商收单</i-col>
          <i-col span="3">工人收单</i-col>
          <i-col span="3">上刊完成</i-col>
        </Row>
        <Row v-for="(item,index) in promiseNodeStatisticsData" :key="'2_' + index" :gutter="4" class="p-t-5">
          <i-col span="4" offset="1">{{item.type}}</i-col>
          <i-col span="3">{{item.materialUpload}} <span v-if="item.type==='完成率'">%</span></i-col>
          <i-col span="3">{{item.contentApproval}} <span v-if="item.type==='完成率'">%</span></i-col>
          <i-col span="3">{{item.publicationSetting}} <span v-if="item.type==='完成率'">%</span></i-col>
          <i-col span="4">{{item.supplierItemNum}} <span v-if="item.type==='完成率'">%</span></i-col>
          <i-col span="3">{{item.workItemNum}} <span v-if="item.type==='完成率'">%</span></i-col>
          <i-col span="3">{{item.publicationFinishNum}} <span v-if="item.type==='完成率'">%</span></i-col>
        </Row>
        <p v-if="promiseNodeStatisticsData.length===0" class="remark p-t-20 text-center">暂无数据</p>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { getSupplierAbility, getPromiseNodeStatistics } from '@/api/dw/manage'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,

      promiseNodeStatisticsData: [],
      supplierAbilityData: []
    }
  },
  mounted () {
    this.initPageData()
    this.loadSupplierAbility()
  },
  methods: {
    initPageData () {
      const queryModel = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId
      }
      getPromiseNodeStatistics(queryModel).then(res => {
        this.promiseNodeStatisticsData = res
      })
    },
    loadSupplierAbility () {
      const queryModel = {
        compareEndDate: this.compareSchedule.endDate,
        compareStartDate: this.compareSchedule.startDate,
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId
      }
      getSupplierAbility(queryModel).then(res => {
        this.supplierAbilityData = res
      })
    },
    getChainRateColor (number) {
      let color = 'text-red'
      if (number < 0) {
        color = 'text-green'
      } else if (number === 0) {
        color = 'text-yellow'
      }
      return color
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    compareSchedule () {
      return this.$store.state.situationWeb.compareSchedule
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.initPageData()
        this.loadSupplierAbility()
      }
    },
    compareSchedule: {
      deep: true,
      handler (newValue) {
        this.loadSupplierAbility()
      }
    }
  }
}
</script>
