import request from '@/utils/requestV2'

// 经营分析合约收入收入类型分析
export function listContractRevenueByType (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/listContractRevenueByType',
    method: 'get',
    params: data
  })
}
// 经营分析合约收入客户贡献分析
export function listContractRevenueByClient (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/listContractRevenueByClient',
    method: 'get',
    params: data
  })
}
// 经营分析合约收入订单转化率
export function listContractOrderCvr (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/listContractOrderCvr',
    method: 'get',
    params: data
  })
}
// 经营分析合约收入合同变更
export function listContractAlteration (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/listContractAlteration',
    method: 'get',
    params: data
  })
}
// 经营分析在刊统计
export function listCountIssue (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/listCountIssue',
    method: 'get',
    params: data
  })
}
// 统计截至今日逾期金额日期分布
export function listOverdueDuration (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/listOverdueDuration',
    method: 'get',
    params: data
  })
}
// 获取时间段内的各类型运维成本及占比
export function listMaintainBillGbType (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/listMaintainBillGbType',
    method: 'get',
    params: data
  })
}
// 执行额结算明细-按类型(增加同比)
export function listExecuteBillGbType (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/listExecuteBillGbType',
    method: 'get',
    params: data
  })
}
// 统计履约各个节点的统计情况
export function getPromiseNodeStatistics (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/getPromiseNodeStatistics',
    method: 'get',
    params: data
  })
}
// 获取各个供应商的工单完成率
export function getSupplierAbility (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/getSupplierAbility',
    method: 'get',
    params: data
  })
}
// 获取各个产品售卖的上刊率，签约金额，签约金额涨幅
export function getProductSalePercentage (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/getProductSalePercentage',
    method: 'get',
    params: data
  })
}
// 获取产品的售卖块天数，按照线路(或渠道商)进行分组
export function getProductSaleComparision (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/getProductSaleComparision',
    method: 'get',
    params: data
  })
}
// 经营分析合约收入分析-代理商维度
export function listContractAmountByCompany (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/listContractAmountByCompany',
    method: 'get',
    params: data
  })
}
// 统计首签、续签和复购的平台品牌数和金额
export function listPlatformBrandManage (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/listPlatformBrandManage',
    method: 'get',
    params: data
  })
}
// 统计首签、续签、复购和流失的品牌列表
export function listPlatformBrandBySignType (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/listPlatformBrandBySignType',
    method: 'get',
    params: data
  })
}
// 统计客户的经分详情
export function listAdvertiserByBrand (data) {
  return request({
    url: '/ooh-dw/v1/datav/manage/listAdvertiserByBrand',
    method: 'get',
    params: data
  })
}
